@import '../Assets/scss/partials/variables';
@import '../Assets/scss/partials/mixins';
@import '../Assets/scss/partials/extensions';

.tags-list {
  list-style-type: none;
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 0.8em;
  margin-top: 1em;
  .tag {
    border-radius: 40px;
    padding: 0.6em 1em 0.6em 1em;
    background: var(--whiteF0);
    @extend %flexCenter;
    font-size: 1.2em !important;
    position: relative;
    gap: 0.6em;
    p {
      color: var(--blue);
      margin: 0 !important;
    }
    img {
      // @extend %floatToYCenter;
      // right: 0.8em;
      // width: 10px;
      // height: 10px;
    }
  }
  .clearFilter {
    background-color: var(--blue) !important;
    padding: 0.6em 1.2em;
    cursor: pointer;
    p {
      color: var(--white) !important;
    }
    &:hover {
      background-color: var(--blue-dark) !important;
    }
  }
}

.dashboard {
  .filters {
    .tag {
      background-color: var(--white) ;
    }
  }
}
