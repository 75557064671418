@import '../../../Assets/scss/partials/variables';
@import '../../../Assets/scss/partials/mixins';
@import '../../../Assets/scss/partials/extensions';

#HowThisItWork {
  padding: 1em 0;
  margin-top: 3em;
  h2 {
    font-family: PoppinsBold;
    font-size: 3.5em;
    line-height: 1em;
    color: var(--blackashgreen);
    margin-bottom: 0.7em;
  }
  .container {
    align-items: flex-start;
    flex-direction: column;
  }
  .hiw-content {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 3em;
  }
  .hiw-text {
    margin-top: 0em;
  }
  .list {
    list-style-type: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-top: 3em;
    li {
      margin: 1em 0;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      p.small {
        flex: 1;
        margin-left: 1em;
        line-height: 1.2em;
      }
    }
    span {
      width: 1.7em;
      height: 1.7em;
      display: grid;
      place-items: center;
      font-size: 1.5em;
      color: var(--white);
      background-color: var(--blue);
      text-align: center;
      @extend %radCircle;
    }
  }
  p {
    font-family: PoppinsRegular;
    font-size: 3.5em;
    line-height: 1em;
    color: var(--greyashgreen);
    &.small {
      font-size: 1.1em;
      line-height: 1.5em;
    }
  }
}
