@use "sass:math";

#Header {
  width: calc(100% - 10px);
  position: fixed;
  top: 0;
  z-index: 999;
  @extend %transitionAll300;
  min-height: 5em;
  display: grid;
  place-items: center;
  border-bottom: 1px solid transparent;
  &.frontend-header {
    border-bottom-color: var(--blackashgreen);
    background: var(--white);
    padding: math.div($btn-size, 2) 0;
    @include phone_and_tabletP {
      padding: 1em 0;
    }
    .container {
      @include phone_and_tabletP {
        width: 100%;
        padding: 0 0 0 1em;
      }
    }
    .logo {
      img {
        width: 100%;
        min-width: 240px;
        @include phone_and_tabletP {
          min-width: 170px;
          width: 170px;
        }
      }
    }
    .links {
      .menu-item {
        > button,
        > a {
          color: var(--blackashgreen) !important;
          &:hover {
            color: var(--blue);
          }
        }
      }
    }
    #hamburger {
      z-index: 30;
      .bar {
        @include phone_and_tabletP {
          background-color: var(--blue);
        }
      }
    }
  }
  &.backend-header {
    background: var(--blue);
    .logo {
      img {
        @include phone_and_tabletP {
          width: 65%;
          min-width: 100px;
        }
      }
    }
  }
  .container {
    gap: $btn-size;
    @include phone_and_tabletP {
      gap: 1em;
      width: 90%;
      padding: 0;
      justify-content: space-between;
    }
  }

  .links {
    list-style-type: none;
    width: 100%;
    display: flex;
    align-items: center;
    gap: 20px;
    justify-content: flex-end;
    @include phone_and_tabletP {
      display: none;
    }
    .menu-item {
      > button {
        background: transparent !important;
        border-color: transparent !important;
        padding: 0 !important;
        &:hover {
          color: var(--blue-dark) !important;
        }
      }
      > .sub-menu {
        position: absolute;
        background: var(--white);
        padding: 20px 20px;
        font-size: 1em;
        border-radius: 10px;
        z-index: 1;
        width: 200px !important;
        top: 50px;
        @extend %shDefault;
        &:before {
          content: "";
          width: 0px;
          height: 0px;
          @extend %floatToXCenter;
          top: -10px;
          left: auto;
          border-left: 10px solid transparent;
          border-right: 10px solid transparent;
          border-bottom: 10px solid var(--white);
        }
        li {
          button {
            color: var(--black);
            padding: 0 !important;
            &:hover {
              color: var(--blue);
            }
          }
        }
      }
    }
  }
  .search-bar {
    margin-right: auto;
    max-width: 100%;
    height: $btn-size;
    position: relative;
    > input {
      width: 100%;
      height: 100%;
      background-color: var(--blue-semi-dark);
      background-image: url("../Images/Search.svg");
      background-repeat: no-repeat;
      background-size: 1em;
      background-position: math.div($btn-size, 2) center;
      outline: none;
      font-size: 0.8em;
      border: 1px solid transparent;
      color: var(--white) !important;
      padding: math.div($btn-size, 2) math.div($btn-size, 2) math.div($btn-size, 2) $btn-size !important;
      &::-webkit-input-placeholder {
        color: var(--white);
      }
      &::-webkit-search-cancel-button {
        -webkit-appearance: none;
        appearance: none;
        height: 10px;
        width: 10px;
        background: transparent url("../Images/cross.svg") center center no-repeat;
        background-size: 10px 10px;
      }
      &:hover {
      }
      &:focus {
      }
      &.input-capsule {
        border-radius: 40px;
      }
    }
  }
  .bell-item {
    .bell-icon {
      padding: 10px;
    }
    .dropdown {
      width: 250px !important;
      border-radius: 1em;
      overflow: visible;
      height: auto;
      text-align: left;

      li {
        list-style: disc;
        color: var(--blue);
        margin: 5px 0px;
        p {
          color: black;
        }
      }
      &:before {
        content: "";
        width: 0px;
        height: 0px;
        @extend %floatToXCenter;
        top: -10px;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-bottom: 10px solid var(--white);
      }
    }
  }
  .logout-item {
  }
  .signup-item {
  }
  #hamburger {
    display: none !important;
    cursor: pointer;
    @include phone_and_tabletP {
      // position: absolute;
      // right: 10px;
      // top: 30px;
      display: flex !important;
      flex-direction: column;
      gap: 5px;
      width: 60px;
      height: 45px;
      position: relative;
      transform: scale(0.5) rotate(0deg);
      transition: 0.5s ease-in-out;
      cursor: pointer;
      z-index: 9999;
    }
    .bar {
      @include phone_and_tabletP {
        position: absolute;
        display: block;
        width: 100%;
        height: 6px;
        margin: 0;
        background-color: var(--white);
        border-radius: 9px;
        opacity: 1;
        left: 0;
        transform: rotate(0deg);
        transition: 0.25s ease-in-out;
        &:nth-child(1) {
          top: 0px;
          transform-origin: left center;
        }
        &:nth-child(2) {
          top: 18px;
          transform-origin: left center;
        }

        &:nth-child(3) {
          top: 36px;
          transform-origin: left center;
        }
      }
    }
    &.close {
      .bar {
        @include phone_and_tabletP {
          background-color: var(--blue);
          transform-origin: unset;
          &:nth-child(1) {
            transform: rotate(45deg);
            top: 50%;
          }
          &:nth-child(2) {
            width: 0%;
            opacity: 0;
          }
          &:nth-child(3) {
            transform: rotate(-45deg);
            top: 50%;
          }
        }
      }
    }
  }
  #mySidenav {
    height: 100%;
    width: 100%;
    position: fixed;
    z-index: 1;
    top: 0;
    right: -100%;
    background-color: var(--white);
    overflow-x: hidden;
    transition: 0.5s;
    padding-top: 20vh;
    text-align: center;
    &.active {
      right: 0;
    }
    a {
      padding: 8px 8px 8px 32px;
      font-family: PoppinsBold;
      text-decoration: none;
      font-size: 3em;
      color: var(--blackOp076);
      display: block;
      transition: 0.3s;
      @include phone_and_tabletP {
        font-size: 1.2em;
        padding: 8px;
      }
    }
    a:hover {
      color: var(--blue);
    }
    .closebtn {
      position: absolute;
      top: 20px;
      right: 25px;
      font-size: 35px;
      color: var(--blackOp076);
      background: transparent;
      border: transparent;
    }
  }
}

.is_sticky {
  #Header {
    top: 0;
    &.frontend-header {
      padding: 0;
    }
  }
}

.logo {
  &:hover {
    cursor: pointer;
  }
}

#global-search {


  input[type="search"] {
    background: #199bdd;
    color: white;
    outline: none;
    background-image: url("../Images/Search.svg");
    background-size: auto;
    background-repeat: no-repeat;
    background-position: 2em center;
    width: 250px !important;
    padding: 0em 1em 0 4em !important;
    &::placeholder {
      color: white;
    }
  }
}
