.root{
    margin-inline: auto;
    display: grid;
    place-items: center;
    /* width: 650px; */
    min-height: 580px;
}
.smiley{
    color:#33b0f2;
    font-size: 100px;
    margin: 0%;
    padding: 0%;
}
.content{
    display: grid;
    place-items: center;
    text-align: center;
    width: 580px;
    height: 360px;
}
.notFoundText{
    color:#33b0f2;
    font-size: 50px;
    font-family: PoppinsBold;
    margin: 0%;
    padding: 0%;
}
.subText{
    font-size: 18px;
    font-family: PoppinsLight;
    margin-top: 10px;
    color: #072C38;
}

@media screen and (max-width: 740px) {
    .content{
        width: 440px;
        height: 300px;
    }
    .notFoundText{
        font-size: 4.5vw;
    }
    .subText{
        font-size: 2.1vw;
        margin-top: 0px;
    }
    .smiley{
        font-size: 12vw;
    }
}
@media screen and (max-width: 500px) {
    .content{
        width: 320px;
    }
    .notFoundText{
        font-size: 5vw;
    }
    .subText{
        font-size: 2.7vw;
    }
}
@media screen and (max-width: 420px) {
    .content{
        height: 250px;
    }
}
@media screen and (max-width: 350px) {
    .content{
        height: 210px;
        width: 280px;
    }
}