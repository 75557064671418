/* Fonts Theme */
@font-face {
  font-family: PoppinsThin;
  src: url('../Fonts/Poppins-Thin.ttf');
}
@font-face {
  font-family: PoppinsExtraLight;
  src: url('../Fonts/Poppins-ExtraLight.ttf');
}
@font-face {
  font-family: PoppinsLight;
  src: url('../Fonts/Poppins-Light.ttf');
}
@font-face {
  font-family: PoppinsRegular;
  src: url('../Fonts/Poppins-Regular.ttf');
}
@font-face {
  font-family: PoppinsMedium;
  src: url('../Fonts/Poppins-Medium.ttf');
}
@font-face {
  font-family: PoppinsSemiBold;
  src: url('../Fonts/Poppins-SemiBold.ttf');
}
@font-face {
  font-family: PoppinsBold;
  src: url('../Fonts/Poppins-Bold.ttf');
}
@font-face {
  font-family: PoppinsBlack;
  src: url('../Fonts/Poppins-Black.ttf');
}
@font-face {
  font-family: PoppinsExtraBlack;
  src: url('../Fonts/Poppins-ExtraBold.ttf');
}
@font-face {
  font-family: YesevaOneReg;
  src: url('../Fonts/YesevaOne-Regular.ttf');
}
/* Fonts Theme */
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}


/* Character Styles */
.unnamed-character-style-1 {
  font-family: var(--unnamed-font-family-poppins);
  font-style: var(--unnamed-font-style-normal);
  font-weight: var(--unnamed-font-weight-normal);
  font-size: var(--unnamed-font-size-35);
  line-height: var(--unnamed-line-spacing-26);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--unnamed-color-072c38);
}
.poppins-—-40pt-h1 {
  font-family: var(--unnamed-font-family-poppins);
  font-style: var(--unnamed-font-style-normal);
  font-weight: var(--unnamed-font-weight-bold);
  font-size: var(--unnamed-font-size-40);
  line-height: var(--unnamed-line-spacing-48);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--unnamed-color-072c38);
}
.poppins-—-30pt-h2 {
  font-family: var(--unnamed-font-family-poppins);
  font-style: var(--unnamed-font-style-normal);
  font-weight: var(--unnamed-font-weight-bold);
  font-size: var(--unnamed-font-size-30);
  line-height: var(--unnamed-line-spacing-34);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--unnamed-color-ffffff);
}
.poppins-—-20pt-h3 {
  font-family: var(--unnamed-font-family-poppins);
  font-style: var(--unnamed-font-style-normal);
  font-weight: var(--unnamed-font-weight-normal);
  font-size: var(--unnamed-font-size-20);
  line-height: var(--unnamed-line-spacing-25);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--unnamed-color-33b0f2);
}
.poppins-—-16pt-body {
  font-family: var(--unnamed-font-family-poppins);
  font-style: var(--unnamed-font-style-normal);
  font-weight: var(--unnamed-font-weight-normal);
  font-size: var(--unnamed-font-size-16);
  line-height: var(--unnamed-line-spacing-25);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--unnamed-color-34525c);
}
.poppins-—-14pt-button {
  font-family: var(--unnamed-font-family-poppins);
  font-style: var(--unnamed-font-style-normal);
  font-weight: var(--unnamed-font-weight-normal);
  font-size: var(--unnamed-font-size-14);
  line-height: var(--unnamed-line-spacing-12);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--unnamed-color-ffffff);
}

