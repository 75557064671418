@import '../Assets/scss/partials/variables';
@import '../Assets/scss/partials/mixins';
@import '../Assets/scss/partials/extensions';

.text-separator {
  width: 80%;
  height: auto;
  display: flex;
  align-items: center;
  gap: 0.5em;
  margin: 1em auto;
  > div {
    flex: 1 1 auto;
    height: 1px;
    background-color: var(--grey);
  }
  > p {
    font-family: PoppinsRegular;
    font-size: 0.8em;
    text-align: center;
    color: var(--grey);
    align-self: center;
  }
}
