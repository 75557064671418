:root {
  @each $color, $value in $colors {
    $dashColor: '--' + $color;
    #{$dashColor}: #{$value};
    // @for $i from 1 through 10 {
    //   @if $i == 10 {
    //     #{$dashColor}: #{$value};
    //   } @else {
    //     #{$dashColor}-alpha#{$i}: rgba($value, $i/10);
    //   }
    // }
    // #{$dashColor}-light: lighten($value, 12%);
    // #{$dashColor}-dark: darken($value, 12%);
  }
  --montserrat: 'Montserrat', sans-serif;
  --monospace: 'Inconsolata', monospace, SFMono-Regular, Menlo, Monaco, Consolas, Courier, 'Liberation Mono',
    'Courier New';
  --unnamed-font-family-poppins: Poppins;
  --unnamed-font-style-normal: normal;
  --unnamed-font-weight-bold: bold;
  --unnamed-font-weight-normal: normal;
  --unnamed-font-size-14: 14px;
  --unnamed-font-size-16: 16px;
  --unnamed-font-size-20: 20px;
  --unnamed-font-size-30: 30px;
  --unnamed-font-size-35: 35px;
  --unnamed-font-size-40: 40px;
  --unnamed-character-spacing-0: 0px;
  --unnamed-line-spacing-12: 12px;
  --unnamed-line-spacing-25: 25px;
  --unnamed-line-spacing-26: 26px;
  --unnamed-line-spacing-34: 34px;
  --unnamed-line-spacing-48: 48px;
  --trans_3s: 0.3s all ease;
  --trans_2s: 0.2s all ease-in-out;
}
