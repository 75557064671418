@use "sass:math";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

ul,
li,
ol {
  list-style: none;
}

html,
body {
  overflow: hidden;
}
.App {
  height: 100vh;
  overflow: hidden;
  overflow-y: auto;
  font-size: 16px;
}

.wordBreak {
  word-break: normal;
  white-space: normal;
}

p.small {
  font-size: 80%;
}

/* Color Theme */

/* Color Theme */
.flexCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}
a {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
}

.svgOnHover {
  width: 2em !important;
  height: 2em !important;
  min-width: 2em !important;
  .svg {
    padding: 0.4em;
    @extend %noUserSelect;
    @extend %transitionAll300;
    fill: currentColor;
    stroke: currentColor;
    cursor: pointer;
    width: 100%;
    height: 100%;
    display: inline-flex;
  }
}

.flex {
  display: flex !important;
}

.flex-inline {
  display: inline-flex !important;
}

.f-wrap {
  flex-wrap: wrap !important;
}

.f-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.f-nowrap {
  flex-wrap: nowrap !important;
}

.f-row {
  flex-direction: row !important;
}

.f-row-reverse {
  flex-direction: row-reverse !important;
}

.f-column {
  flex-direction: column !important;
}

.f-column-reverse {
  flex-direction: column-reverse !important;
}

.f-content-baseline {
  align-content: baseline !important;
}

.f-content-center {
  align-content: center !important;
}

.f-content-end {
  align-content: flex-end !important;
}

.f-content-start {
  align-content: flex-start !important;
}

.f-content-start {
  align-content: flex-start !important;
}

.f-content-around {
  align-content: space-around !important;
}

.f-content-between {
  align-content: space-between !important;
}

.f-row-reverse {
  flex-direction: row-reverse !important;
}

.f-row {
  flex-direction: row !important;
}

.f-justify-start {
  justify-content: flex-start !important;
}

.f-justify-center {
  justify-content: center !important;
}

.f-justify-end {
  justify-content: flex-end !important;
}

.f-justify-between {
  justify-content: space-between !important;
}

.f-justify-around {
  justify-content: space-around !important;
}

.f-align-start {
  align-items: flex-start !important;
}

.f-align-center {
  align-items: center !important;
}

.f-align-end {
  align-items: flex-end !important;
}

.f-align-baseline {
  align-items: baseline !important;
}

.f-align-stretch {
  align-items: stretch !important;
}

.f-align-self-start {
  align-self: flex-start !important;
}

.f-align-self-center {
  align-self: center !important;
}

.f-align-self-end {
  align-self: flex-end !important;
}

.f-align-self-baseline {
  align-self: baseline !important;
}

.f-align-self-stretch {
  align-self: stretch !important;
}

.f-justify-self-start {
  justify-self: flex-start !important;
}

.f-justify-self-center {
  justify-self: center !important;
}

.f-justify-self-end {
  justify-self: flex-end !important;
}

.f-justify-self-baseline {
  justify-self: baseline !important;
}

.user-info {
  display: flex;
  position: relative;
  .proImage {
    width: 6.5em;
    height: 6.5em;
    position: relative;
    @extend %radCircle;
    overflow: hidden;
    @include phone_and_tabletP {
      width: 4.5em;
      height: 4.5em;
    }
    img {
      height: 100%;
      width: auto;
      @extend %floatToCenter;
      @extend %radCircle;
    }
  }
  .info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 1.5em;
    h1 {
      font-size: 1.8em;
      font-weight: 500 !important;
      @include phone_and_tabletP {
        font-size: 1em;
      }
    }
    .proImage {
      @include phone_and_tabletP {
        width: 5.5em;
        height: 5.5em;
      }
    }
    span {
      margin-top: 0.75em;
      display: flex;
      align-items: end;
      @include phone_and_tabletP {
        font-size: 0.7em;
      }
    }
    img {
      margin-right: 0.5em;
      width: 0.75em;
      height: 1.2em;
    }
  }
}

.gallery-popup {
  .MuiPaper-elevation {
    max-height: 95vh !important;
    height: 90vh !important;
    width: max-content !important;
    min-width: unset !important;
    max-width: unset !important;
    .zoom-image {
      img {
      }
    }
  }
}
.center {
  text-align: center;
}

.hamburger-dropdown {
  text-align: center;
  input {
    border: 1px solid;
  }
  .dropdown {
    width: 200px !important;
  }
}

#profile-settings-btn {
  min-width: inherit;
  height: auto;
  @include phone_and_tabletP {
    font-size: 0.5em;
  }
}

.invite-select {
  background: url("./../Images/Select_arrow.svg") !important;
  background-repeat: no-repeat !important;
  background-position: calc(100% - 20px) center !important;
  background-size: 0.9em;
  border-radius: 10px 10px 0px 0px !important;
}

.invite-options {
  cursor: pointer;
  width: 100%;
  height: auto;
  position: absolute;
  border-radius: 0px 0px 10px 10px !important;
  overflow: hidden;
  overflow-y: scroll;
  border: 1px solid var(--grey);
  background: white;
  padding: 1em 1em;
  z-index: 999;
  .trade-item {
    padding: 1em 0em;
  }
  .trade-info {
    padding: 1em 0em;
  }
}

.animated {
  border-bottom-left-radius: 25%;
  border-bottom-right-radius: 10px;
  border-top-left-radius: 10px;
  border-top-right-radius: 100px;
}


.feed-mg-b{
  margin-bottom: 5em;
  @include phone_and_tabletP {
    margin-bottom: 3em ;

  }
}

.profile-view-mg{
  padding: 4em 0em;
  @include phone_and_tabletP {
    padding: 3em 0em;

  }
}

.review-container{
  margin: 1.5em 0;;
}