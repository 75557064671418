@import '../../../Assets/scss/partials/variables';
@import '../../../Assets/scss/partials/mixins';
@import '../../../Assets/scss/partials/extensions';

.slider-content {
  @extend %flexCenter;
  padding: 100px 0 50px 0;
  gap: 2em;
  @include phone_and_tabletP {
    padding: 3em 1.4em;
    flex-direction: column;
  }
  > div {
    display: flex;
    flex-direction: column;
    gap: 2em;
    @include phone_and_tabletP {
      gap: 1em;
      text-align: left;
    }
  }
  h1.title {
    font-family: PoppinsBold;
    font-size: 3.5em;
    line-height: 1em;
    color: var(--blackashgreen);
    margin-bottom: 0.5em;
    width: 100%;
    @include phone_and_tabletP {
      font-size: 1.5em;
      line-height: 1em;
      margin: 0 auto 0em auto;
    }
  }
  .sub-title {
    font-family: PoppinsLight;
    font-size: 1.5em;
    line-height: 1.35em;
    color: var(--greyashgreen);
    margin-bottom: 0.5em;
    width: 75%;
    @include phone_and_tabletP {
      width: 100%;
      font-size: 1em;
    }
  }
}
