@import '../Assets/scss/partials/variables';
@import '../Assets/scss/partials/mixins';
@import '../Assets/scss/partials/extensions';

#Footer {
  background: var(--blackashgreen);
  .container {
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    flex-direction: column;
  }
  .footerTop {
    margin-top: 1.5em;
    width: 100%;
    @include phone_and_tabletP {
      padding: 0 2em !important;
    }
    .pages {
      list-style-type: none;
      display: flex;
      justify-content: space-between;
      color: var(--white);
      flex-wrap: wrap;
      @include phone_and_tabletP {
      }
      .page {
        min-width: 10em;
        flex-basis: 1;
        margin-bottom: 2em;
        @include phone_and_tabletP {
          display: flex;
          flex-direction: column;
          width: 100%;
        }
        .pageLink {
          font-size: 0.8em;
          margin-bottom: 2.5em;
          @include phone_and_tabletP {
            margin-bottom: 1em;
            font-size: 1em;
          }
        }
        .subLinks {
          list-style-type: none;
          font-size: 0.9em;
          @include only_phoneL {
            font-size: 2em;
          }
          @include phone_and_tabletP {
            font-size: 1em;
          }
          .subLink {
            margin-bottom: 0.5em;
            a {
              cursor: pointer;
              &:hover {
                text-decoration: underline;
              }
            }
          }
          .social {
            display: flex;
            align-items: center;
            color: var(--white);
            img {
              margin-right: 0.75em;
              height: 1.9em;
              width: auto;
            }
          }
        }
      }
    }
  }
  .line {
    width: 100%;
    height: 1px;
    background-color: var(--white);
  }
  .forBottom {
    width: 100%;
    display: flex;
    align-items: center;
    font-size: 0.8em;
    color: var(--white);
    margin-top: 2em;
    margin-bottom: 2em;
    @include phone_and_tabletP {
      padding: 0 2em !important;
      flex-direction: column;
      line-height: 2em;
    }
    div {
      margin-left: 1em;
      margin-right: 1em;
    }
    .verticalLine {
      height: 20px;
      width: 1px;
      background-color: var(--white);
      @include phone_and_tabletP {
        display: none;
      }
    }

    a {
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.App.create-account,
.App.login {
  .content {
    margin-top: 140px;
  }
  #Footer {
    .footerTop {
      .pages {
        .page {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-bottom: 2em;
          gap: 1em;
          .pageLink {
            margin-bottom: 0;
          }
          .subLinks {
            .subLink {
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }
}
