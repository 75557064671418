@use 'sass:math';

input {
  padding: 0.5em 1.5em !important;
  line-height: 3.6em !important;
  height: 3.6em !important;
  min-height: 3.6em !important;
  position: relative;
  border-radius: 40px !important;
  border: transparent;
  font-family: PoppinsRegular;
  &.has-icon {
    padding: 0.5em 2em 0.5em 3em !important;
    background-position: 1.3em center !important;
  }
  &[list='countries'],&[list='trades'] {
    background-position: calc(100% - 20px) center !important;
    background-size: 0.9em;
  }
  &::-webkit-calendar-picker-indicator {
    display: none !important;
  }
  &[type="checkbox"] {
    line-height: 13px !important;
    height: 13px !important;
    min-height: 13px !important;
    padding: 0px !important;
  }
  &.input-lg {
    font-size: 1em;
  }
}
#togglePassword {
  width: 24px !important;
  height: 24px;
  position: absolute;
  top: 11px;
  right: 10px;
  background: transparent;
  border: 0;
  @extend %flexCenter;
  cursor: pointer;
  img {
    width: auto;
    height: inherit;
  }
}
.form-column {
  display: inline-flex;
  gap: 1em;
  margin-bottom: 1em;
  justify-content: space-between;
  position: relative;
}
.form-row {
  margin: 0 0 1em 0;
  position: relative;
}

.dropdown {
  background: var(--white);
  position: absolute;
  top: calc(100% + 0.7em);
  padding: 2em;
  font-size: 12px;
  border-radius: 1em 0 0 1em;
  z-index: 1;
  width: 400px;
  height: 200px;
  overflow-x: hidden;
  overflow-y: auto;
  border: 1px solid var(--gray-200);
  @extend %shDefault;
  &.center {
    left: 50%;
    right: 50%;
    transform: translateX(-50%);
  }
  h3 {
    font-weight: 500;
  }
}
