@use 'sass:math';

.content {
  margin-top: 80px;
  @include tabletP {
    min-height: 700px;
  }
}

/* Global*/

.pageContent {
  width: 1380px;
  padding: 0 40px;
}

/* Global*/

.container-fluid {
  width: 100%;
  display: flex;
  margin: 0;
  padding: 0;
}
.container {
  width: 1380px;
  margin: 0 auto;
  padding: 0;
  display: flex;
  align-items: center;
  position: relative;
  @media only screen and (min-width: 280px) {
    width: 100%;
  }
  @media only screen and (min-width: 425px) {
    width: 90%;
  }
  @media only screen and (min-width: 768px) {
    width: 755px;
  }
  @media only screen and (min-width: 1024px) {
    width: 980px;
  }
  @media only screen and (min-width: 1280px) {
    width: 1200px;
  }
  @media only screen and (min-width: 1440px) {
    width: 1380px;
  }
}

.grid-container {
  position: relative;
  display: flex !important;
  justify-content: center;
  align-items: flex-start;
  $grid-column-left: 17.2em;
  $grid-column-right: 8em;
  @include tabletP {
    gap: 2em;
  }
  @include phone_and_tabletP {
    width: 95%;
    flex-direction: column;
    margin: 0 auto;
  }
  > div {
    min-height: 5px;
    margin: 0 auto 2.5em auto;
    @include phone_and_tabletP {
      width: 100%;
    }
    &:nth-child(1) {
      @include tabletP {
        width: $grid-column-left;
      }
    }
    &:nth-child(2) {
      @include tabletP {
        width: calc(100% - ($grid-column-left + $grid-column-right));
      }
      @include phone_and_tabletP {
        width: 100%;
      }
    }
    &:nth-child(3) {
      @include tabletP {
        width: $grid-column-right;
      }
      @include phone_and_tabletP {
        display: none;
      }
    }
    > h2 {
      width: 95%;
      margin: 0 auto 0.8em auto;
    }
  }
  &.gc-center {
    $grid-column: 5em;
    > div {
      &:nth-child(1) {
        width: $grid-column;
      }
      &:nth-child(2) {
        width: calc(100% - math.div($grid-column, 2));
      }
      &:nth-child(3) {
        width: $grid-column;
      }
      @include phone_and_tabletP {
        width: 100% !important;
      }
    }
  }
  .header {
    position: relative;
    margin: 2em;
    width: calc(100% - 2em);
    display: flex;
    align-items: center;
    @include phone_and_tabletP {
      margin: 2em 1em 1.5em 1em;
    }
    h2 {
      font-family: PoppinsRegular;
      font-size: 1.5em;
      font-weight: normal;
      line-height: auto;
      color: var(--blackashgreen);
      @include phone_and_tabletP {
        font-size: 1.5em;
        color: var(--blackashgreen);
      }
    }
    .go-back-btn {
      width: 80px;
      height: 50px;
      @extend %floatToYCenter;
      right: 0;
      cursor: pointer;
      .back,
      .backArrow {
        position: absolute;
        top: 38%;
        transform: translateY(-50%);
        @extend %transitionAll300;
      }
      .back {
        right: 0;
      }
      .backArrow {
        right: 10px;
        visibility: hidden;
      }
      &:hover {
        .backArrow {
          visibility: visible;
          right: calc(100% - 50px);
          @extend %transitionAll300;
        }
      }
    }
  }
  .sidebar {
    padding: 0.5em 1.5em 0.5em 3em;
    background-color: var(--white);
    border-radius: 15px;
    @include phone_and_tabletP {
      margin-top: 2em !important;
      padding-left: 2.5em;
      padding-right: 2.5em;
      min-height: 1em;
      width: 98%;
      margin-inline: auto;
    }
    ul {
      font-family: PoppinsRegular;
      font-size: 0.9em;
      color: var(--grey);
      padding: 1em 0;
      li {
        font-weight: 600;
        cursor: pointer;
        margin: 5px 0;
        list-style-type: square;
        &::marker {
          color: transparent;
        }
        &:hover {
          color: var(--blue);
        }
        &.active {
          color: var(--blue);
          &::marker {
            color: var(--blue);
          }
        }
        &.activeFilter {
          color: var(--blue);
          &::marker {
            color: var(--blue);
          }
        }
      }
    }
    .edit-icon {
      position: absolute;
      right: 0;
      top: 0;
    }
    .skills {
      position: relative;
      @include phone_and_tabletP {
        display: none;
      }
    }
    .filter {
      display: none;
      @include phone_and_tabletP {
        width: 100%;
        padding-top: 1em;
        padding-bottom: 1em;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      .feed {
        @include phone_and_tabletP {
          display: none;
        }
      }
      img {
        @include phone_and_tabletP {
          width: 1.2em;
          transform: rotate(270deg);
        }
      }
      .reverse {
        @include phone_and_tabletP {
          transform: rotate(90deg);
        }
      }
    }
  }
}

.custom_round_box {
  position: relative;
  padding-bottom: 0;
  margin: 0em 2em 1.5em 2em;
  width: 100%;
  min-height: 75vh;
  display: flex;
  flex-direction: column;
  @include phone_and_tabletP {
    display: flex;
    flex-direction: column;
  }
  .blank {
    @include phone_and_tabletP {
      display: none;
      background: var(--white);
      min-height: 0vh;
      width: 100%;
    }
  }
  h2 {
    font-family: PoppinsRegular;
    font-size: 1.5em;
    font-weight: normal;
    line-height: auto;
    color: var(--blackashgreen);
  }
  .blank {
    background: var(--white);
  }
  .heading {
    padding: 2em 1.5em;
  }
  .content {
    padding: 2em 1.5em;
  }
  .noData {
    text-align: center;
    padding: 2em 1em;
  }
}

.aboutGallery {
  position: relative;
  width: 100%;
  > div {
    position: relative;
  }
}
