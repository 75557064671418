@import '../../../Assets/scss/partials/variables';
@import '../../../Assets/scss/partials/mixins';
@import '../../../Assets/scss/partials/extensions';

#Testimonials {
  padding: 1em;
  width: 100%;
  overflow: hidden;
  .container {
    justify-content: center;
    flex-direction: column;
  }
  .testimonial-list {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    gap: 50px;
    @include phone_and_tabletP {
      flex-direction: column;
    }
    li {
      width: 22.3em;
      img {
        z-index: 1;
        width: 100%;
      }
      @include phone_and_tabletP {
        width: 15em;
      }
    }
  }
  .title {
    position: relative;
    width: 100%;
    h2 {
      font-family: PoppinsBold;
      font-size: 3.5em;
      line-height: 1em;
      color: var(--blackashgreen);
      margin-bottom: 1em;
      text-align: left;
      width: max-content;
      position: relative;
      @include phone_and_tabletP {
        font-size: 2em;
        line-height: 1em;
        text-align: center;
        margin: 0 auto 1em auto;
      }
      img {
        width: 150px;
        position: absolute;
        top: 50%;
        left: calc(100% + 100px);
        transform: translateY(-50%);
        @include phone_and_tabletP {
          display: none;
        }
      }
    }
  }
  blockquote {
    font-size: 1.5em;
    font-family: PoppinsSemiBold;
    margin: 2em 0 0 0;
    line-height: 1.2em;
    color: var(--blue);
  }
  hr {
    background: var(--grey);
    height: 1px;
    width: 80%;
    margin: 1em 0;
  }
}
