@use 'sass:math';

@import '../../Assets/scss/partials/variables';
@import '../../Assets/scss/partials/mixins';
@import '../../Assets/scss/partials/extensions';

.dashboard-section {
  background: var(--whiteF0);
  min-height: 90vh;
  .container {
    .sidebar {
      margin-top: 0 !important;
    }
  }
  .search {
    width: 98%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1em;
    margin: 2.5em auto;
    .search-area {
      position: relative;
      font-size: 0.8em;
      width: calc(100% - 12.5em);
      input {
        color: var(--blackashgreen) !important;
        background-color: var(--white) !important;
        border: none !important;
        padding-right: 0em;
        padding-left: 1.95em;
        min-height: 2.8em;
        font-size: 1em;
        border-radius: 27px;
        &::-webkit-input-placeholder {
          color: var(--charcoal-300);
        }
        &::-webkit-search-cancel-button {
          -webkit-appearance: none;
        }
      }
    }
    button {
      min-width: 9.8em;
    }
  }
  .filters {
    // margin-bottom: 2em;
    width: 98%;
    margin: 2em auto;
  }
  .each-trade {
    padding: 2em 2em !important;
    .currentSelection {
      margin: 0.5em 0;
    }
    .tag {
      padding: 0.6em 1.2em !important;
    }
  }
}
