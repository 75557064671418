.bg-white {
  background-color: var(--white) !important;
}

.bg-blue {
  background-color: var(--blue) !important;
}

.bg-charcoal-300 {
  background-color: var(--charcoal-300) !important;
}

.bg-transparent {
  background: transparent !important;
}
