.notif {
  @extend %posRelative;
  .notif-count {
    @extend %posAbsolute;
    font-size: 11px;
    top: 2px;
    margin: 0;
    text-align: center;
    padding: 1px 4px;
    border-radius: 5px;
    color: var(--white);
    background-color: var(--red);
    font-family: var(--monospace);
    @include user-select(none);
    &.no-count {
      padding: 0;
      @extend %radCircle;
      width: 0.7em;
      height: 0.7em;
      top: 0.3em;
      right: 0.3em;
      @include animation (pulse 1s ease infinite);
    }
    &.left {
      left: 2px;
    }
    &.right {
      right: 2px;
    }
  }
}

@include keyframes(pulse) {
  0% {
      @include box-shadow(0 0 0 0 var(--red));
  }
  70% {
      @include box-shadow(0 0 0 10px transparent);
  }
  100% {
      @include box-shadow(0 0 0 0 transparent);
  }
}