@use "sass:math";

@import "../../Assets/scss/partials/variables";
@import "../../Assets/scss/partials/mixins";
@import "../../Assets/scss/partials/extensions";

.messages-section {
  background: var(--whiteF0);
  .custom_round_box {
    overflow-y: auto;
    scroll-behavior: smooth;
    display: inline-flex;
    flex-direction: row;
  }
  .search {
    padding: 1em;
    input {
      width: 100%;
      height: 100%;
      background-color: var(--gray-200);
      background-image: url("/Assets/Images/search_gray.svg");
      background-repeat: no-repeat;
      background-size: 1em;
      background-position: math.div($btn-size, 2) center;
      outline: none;
      font-size: 0.8em;
      font-family: PoppinsRegular;
      border: 1px solid transparent;
      color: var(--black) !important;
      padding: math.div($btn-size, 2) math.div($btn-size, 2) math.div($btn-size, 2) $btn-size !important;
      &::-webkit-input-placeholder {
        color: var(--black);
      }
      &::-webkit-search-cancel-button {
        -webkit-appearance: none;
        appearance: none;
        height: 10px;
        width: 10px;
        background: transparent url("/Assets/Images/cross_gray.svg") center center no-repeat;
        background-size: 10px 10px;
      }
      &:hover {
      }
      &:focus {
      }
      &.input-capsule {
        border-radius: 40px;
      }
    }
  }
  .chat-list-sidebar {
    width: 300px;
    min-height: 80vh;
    border-right: 1px solid var(--greyLight);
    ul {
      padding: 0 !important;
      margin: 0 !important;
      .chatItem {
        cursor: pointer;
        .chatContent {
          display: flex;
          align-items: center;
          gap: 0.9em;
          padding: 0.6em;
          &:hover {
            h4 {
              cursor: pointer;
              color: var(--blue);
            }
          }
          .chatInfo {
            width: 11.85em;
            h4 {
              font-weight: 500;
              width: inherit;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
            p {
              width: inherit;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }
        .chatContent img {
          width: 3.9em;
          height: 3.9em;
        }
        &.active {
          background-color: var(--gray-200);
        }
      }
    }
  }
  .message-area {
    width: calc(100% - 300px);
    @include phone_and_tabletP {
      width: 100%;
    }
    .chatHeader {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 2.25em;
      padding: 0.93em 2em;
      .rowGrdid {
        height: 100%;
        display: grid;
        grid-template-rows: 5.8em 1px auto 1px 5.3em;
      }
      .profileName {
        display: flex;
        align-items: center;
        gap: 0.5em;
        img {
          width: 3em;
          height: 3em;
        }
        h3 {
          color: var(--blue);
        }
        .backArrow {
          width: 2.1em;
          height: 2.1em;
        }
      }
    }
    .chats {
      height: 70vh;
      overflow-y: auto;
      scroll-behavior: smooth;
      @include phone_and_tabletP {
        height: 600px;
      }
      &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 0px rgba(0, 0, 0, 0);
        border-radius: 0px;
        background-color: #f5f5f5;
      }

      &::-webkit-scrollbar {
        width: 5px;
        background-color: var(--blue);
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 0px;
        -webkit-box-shadow: inset 0 0 0px rgba(0, 0, 0, 0);
        background-color: var(--blue);
      }
    }
    .chatFooter {
      width: 100%;
      display: grid;
      grid-template-columns: 4em auto 9.45em;
      position: relative;
      padding: 1em 0;
      .itemCenter {
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .attachments {
        width: calc(100% - 20em);
        @include phone_and_tabletL {
          width: min-content;
        }
        position: absolute;
        padding: 1em;
        border-top-left-radius: 1em;
        border-top-right-radius: 1em;
        bottom: calc(100% + 1px);
        left: 5em;
        background-color: var(--gray-200);
        li {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .cancel-btn {
          position: absolute;
          right: 0.1em;
          top: 0.1em;
          transform: scale(0.7);
          padding: 0 !important;
        }
      }
      .attachment-options {
        // padding: 0.5em;
        border-radius: 40px;
        background-color: var(--blue);
        position: absolute;
        bottom: calc(100% - 0.5em);
        .file-uploader {
          button {
            min-width: unset !important;
            min-height: unset !important;
            width: 4em !important;
            height: 4em !important;
            padding: 1em !important;
            img {
              @extend %floatToCenter;
              width: auto !important;
              height: 1.5em !important;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}
