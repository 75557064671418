@use 'sass:math';

@import '../../Assets/scss/partials/variables';
@import '../../Assets/scss/partials/mixins';
@import '../../Assets/scss/partials/extensions';

.mytrade-section {
  background: var(--whiteF0);
  min-height: 90vh;
  .trade-item {
    margin: 0.5em 0;
    @extend %transitionAll300;
    cursor: pointer;
    .para2 {
    }
    .for {
      h5 {
        color: var(--blackashgreen);
      }
    }
    .hover-icon {
      use {
        display: block;
        @extend %transitionAll300;
        + use {
          display: none;
          @extend %transitionAll300;
        }
      }
    }
    &:hover {
      @extend %transitionAll300;
      .hover-icon {
        use {
          display: none;
          @extend %transitionAll300;
          + use {
            display: block;
            @extend %transitionAll300;
          }
        }
      }
    }
  }
  .about-trade {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    margin: 1.6em 0;
    > div {
      &:nth-child(1) {
        width: 60%;
      }
      &.trade-status {
        ul {
          display: flex;
          align-items: flex-end;
          flex-direction: column;
          width: max-content;
          li {
            &.status-active {
              list-style-type: square;
              color: var(--blue);
            }
            &.status-pending {
              list-style-type: square;
              color: var(--warning);
            }
            &.status-completed {
              list-style-type: square;
              color: var(--success);
            }
            p {
              text-transform: capitalize;
            }
          }
        }
      }
    }
    h2{
      @include phone_and_tabletL{
        font-size: 1.2em;;
      }
    }
  }
  .offers-info,
  .trade-info {
    display: flex;
    flex-direction: column;
    gap: 1em;
    .tag {
      padding: 0.6em 1.2em !important;
    }
    p {
      margin: 0.8em  0;
      line-height: auto;
      word-break: normal;
      white-space: normal;
    }
    .lookingFor,
    .exchangeFor {
       @extend %transitionAll300;
    
    }
  }
  .offers-item {
    margin: 0.5em 0;
    @extend %transitionAll300;
    cursor: pointer;
    .para2 {
    }
    .for {
      h5 {
        color: var(--blackashgreen);
        margin-bottom: 0.5em;
      }
    }
    .hover-icon {
      use {
        display: block;
        @extend %transitionAll300;
        + use {
          display: none;
          @extend %transitionAll300;
        }
      }
    }
    &:hover {
      @extend %transitionAll300;
      .hover-icon {
        use {
          display: none;
          @extend %transitionAll300;
          + use {
            display: block;
            @extend %transitionAll300;
          }
        }
      }
    }
  }
  .about-offers {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    margin: 1.6em 1em;
    > div {
      &:nth-child(1) {
        width: 60%;
      }
      &.offers-status {
        ul {
          display: flex;
          align-items: flex-end;
          flex-direction: column;
          width: max-content;
          li {
            &.status-active {
              list-style-type: square;
              color: var(--blue);
            }
            &.status-pending {
              list-style-type: square;
              color: var(--warning);
            }
            &.status-accepted,
            &.status-completed {
              list-style-type: square;
              color: var(--success);
            }
            p {
              text-transform: capitalize;
            }
          }
        }
      }
    }
    h2{
      @include phone_and_tabletL{
        font-size: 1.2em;;
      }
    }
  }
  .each-trade {
    padding: 1em 2em !important;
    .currentSelection {
      margin: 0.5em 0;
    }
    .tag {
      padding: 0.6em 1.2em !important;
    }
  }
}
