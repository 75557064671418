@import '../../../Assets/scss/partials/variables';
@import '../../../Assets/scss/partials/mixins';
@import '../../../Assets/scss/partials/extensions';

#meet-the-traders {
  padding: 100px 0 50px 0;
  @extend %flexCenter;
  .container {
    flex-direction: column;
  }
  h2.title {
    font-family: PoppinsBold;
    font-size: 3.5em;
    line-height: 1em;
    color: var(--blackashgreen);
    margin-bottom: 2em;
    text-align: left;
    width: 100%;
    @include phone_and_tabletP {
      width: 80%;
      font-size: 2em;
      line-height: 1em;
      text-align: center;
      margin: 0 auto 1em auto;
    }
  }
  ul.traders-list {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    @include phone_and_tabletP {
      flex-direction: column;
      gap: 2em;
    }
    li {
      margin-bottom: 4em;
      width: 33.33%;
      display: flex;
      flex-direction: column;
      align-items: center;
      min-height: 31em;
      z-index: 0;
      background-color: var(--blackOp05);
      @extend %transitionAll300;
      border-radius: 15px;
      position: relative;
      overflow: hidden;
      @include phone_and_tabletP {
        margin-bottom: 0em;
        width: calc(100% - 60px);
      }
      &:nth-child(1),
      &:nth-child(3) {
        .image {
          &:after {
            content: '';
            width: 100%;
            height: 100%;
            @extend %floatToCenter;
            background-color: rgba(0, 0, 0, 0.05);
          }
        }
      }
      .image {
        width: 100%;
        height: 420px;
        overflow: hidden;
        display: flex;
        position: relative;
        border-radius: 15px;
        background-color: #d8f1ff;
        img {
          width: 110%;
          height: auto;
          @extend %floatToXCenter;
          bottom: 0;
        }
      }
      h3 {
        font-family: PoppinsBold;
        color: var(--blackashgreen);
        font-size: 1.5em;
        margin: 18px 0 9px 0;
      }
      .intro {
        font-size: 0.9em;
        margin: 0.5em 2em;
        width: 90%;
        line-height: 1.5em;
        text-align: center;
        color: var(--grey);
        min-height: 60px;
      }
      &:hover,
      &.active {
        background-color: var(--whitesmoke);
        @include tabletP {
          transform: scale(1.2, 1.2);
        }
      }
      &:hover {
        z-index: 3;
      }
      &.active {
        z-index: 2;
      }
    }
  }

  // .sub-title {
  //   font-family: PoppinsLight;
  //   font-size: 1.5em;
  //   line-height: 1.35em;
  //   color: var(--greyashgreen);
  //   margin-bottom: 0.5em;
  //   max-width: 75%;
  // }
}
