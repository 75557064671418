@use 'sass:math';

button,
.btn {
  outline: none;
  cursor: pointer;
  color: var(--white);
  background-color: var(--transparent);
  border: 1px solid transparent;
  font-family: PoppinsRegular;
  min-width: $btn-size;
  padding: 0.8em 2em;
  position: relative;
  @extend %transitionAll300;
  img {
    @extend %transitionAll300;
  }
  span {
    @extend %transitionAll300;
  }
  &:hover {
    @extend %transitionAll300;
    img {
      @extend %transitionAll300;
    }
    span {
      @extend %transitionAll300;
    }
  }
  &.btn-capsule {
    border-radius: 40px;
  }
  &.btn-link {
    padding: 0 !important;
  }
  &.btn-round {
    @extend %radCircle;
    width: $btn-size !important;
    min-width: $btn-size !important;
    height: $btn-size !important;
    padding: 0;
  }
  &.btn-block {
    width: 100%;
  }
  &.slider-btn {
    width: max-content;
    min-width: 10em;
    font-size: 1em !important;
    @include phone_and_tabletP {
    }
  }
  &.signup-btn {
    min-width: 10em;
  }
  &.profile-btn {
    width: $btn-size;
    height: $btn-size;
    overflow: hidden;
    img {
      @extend %floatToCenter;
      width: auto;
      height: 100%;
    }
  }
  &.logout-btn {
    min-width: 10em !important;
    border-color: var(--white) !important;
    .sadFace {
      display: none;
    }
    &:hover {
      background-color: var(--blue-semi-dark) !important;
      border-color: transparent !important;
      .sadFace {
        display: block;
      }
      .logout {
        display: none;
      }
    }
  }
  &.signup-btn {
    .sadFace {
      display: none;
    }
    &:hover {
      .sadFace {
        display: block;
      }
      .logout {
        display: none;
      }
    }
  }
  &.search-btn {
    @extend %floatToYCenter;
    right: 0.2em !important;
    @extend %flexCenter;
    & img {
      width: 1em;
      height: auto;
    }
    &:hover {
      & img {
        transform: rotate(25deg);
      }
    }
  }
  &.edit-icon {
    width: 2.4em !important;
    min-width: 2.4em !important;
    height: 2.4em !important;
    position: absolute;
    right: 2em;
    top: 2em;
  }
  &.google-btn {
    background-color: var(--green) !important;
    img {
      @extend %floatToYCenter;
      left: 0;
    }
    &:hover {
      background-color: var(--blue-dark) !important;
    }
  }
  &.bg-blue {
    border-color: var(--blue) !important;
    &:hover {
      border-color: var(--blue-semi-dark);
      background-color: var(--blue-dark) !important;
    }
    &.btn-outline {
      color: var(--blue) !important;
      &:hover,
      &.active {
        border-color: var(--blue-dark) !important;
        color: var(--white) !important;
        background-color: var(--blue-dark) !important;
        .svg {
          color: var(--white) !important;
        }
      }
    }
  }
  &.bg-charcoal-300 {
    border-color: var(--charcoal-300) !important;
    &:hover {
      border-color: var(--charcoal);
      background-color: var(--charcoal);
    }
    &.btn-outline {
      color: var(--charcoal-300) !important;
      &:hover,
      &.active {
        border-color: var(--charcoal) !important;
        color: var(--white) !important;
        background-color: var(--charcoal) !important;
        .svg {
          color: var(--white) !important;
        }
      }
    }
  }
  &.btn-outline {
    background: transparent !important;
  }
  span {
    @extend %transitionAll300;
  }
}

.addTags {
  display: grid;
  position: relative;
  $big-input-size: 4.4em;
  input {
    font-size: 1em;
    background: var(--white);
    width: 100%;
    background-repeat: no-repeat;
    background-size: 1em;
    background-position-x: 0.5em;
    background-position-y: center;
    color: var(--grey);
    border-radius: 40px;
    border: 1px solid var(--grey);
    outline: none;
    min-height: unset !important;
    height: $big-input-size !important;
    padding: 0.5em 9em 0.5em 2em !important;
  }
  button {
    position: absolute;
    right: 1em;
    top: 0.8em;
    font-size: 0.8em !important;
    min-width: 9em !important;
    min-height: unset !important;
    height: calc($big-input-size - 0.6em) !important;
  }
}
