.text-center {
  text-align: center !important;
}

.text-blue {
  color: var(--blue) !important;
}

.icon-blue {
  color: var(--blue) !important;
  &:hover,
  &.active {
    color: var(--white) !important;
  }
}
.icon-greyLight {
  color: var(--greyLight) !important;
  &:hover,
  &.active {
    color: var(--black) !important;
  }
}
