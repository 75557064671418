// // PLACEHOLDER
// input::placeholder input::-webkit-input-placeholder,
// input:-moz-placeholder,
// input::-moz-placeholder,
// input:-ms-input-placeholder {
//   color: var(--gray);
//   @include transition(all 300ms ease);
//   &:focus {
//     color: var(--gray-dark);
//     @include transition(all 300ms ease);
//   }
// }

// SCROLLBAR
.App {
  &::-webkit-scrollbar {
    width: 10px;
    height: 10px;
    background-color: transparent;
  }
  &::-webkit-scrollbar-track,
  &::-webkit-scrollbar-thumb {
    border: 3px solid transparent;
    background-clip: padding-box;
  }
  &::-webkit-scrollbar-track {
    background-color: var(--blue-washed);
  }
  &::-webkit-scrollbar-thumb {
    background-color: var(--blue);
    &:hover {
      border: 1px solid var(--blue-dark);
    }
  }
}

// FILE
// input[type="file"]::-webkit-file-upload-button {
//     visibility: hidden;
// }

// DATE
// input[type='date']::-webkit-inner-spin-button,
// input[type='date']::-webkit-outer-spin-button,
// input[type='date']::-webkit-calendar-picker-indicator {
//   @include appearance(none);
//   display: none;
//   margin: 0;
// }
// input[type='date']::-webkit-calendar-picker-indicator {
//   background-image: url('/assets/img/icons/svg/chevron-down.svg');
//   background-position: -43px;
//   background-size: 456px;
//   background-repeat: no-repeat;
//   padding: 11px 14px;
//   background-color: var(--gray-alpha5);
//   opacity: 1;
//   width: 0px;
//   height: 14px;
//   display: block;
//   border-left: 1px solid var(--theme-gray);
//   color: var(--theme-gray);
//   cursor: pointer;
//   &:active,
//   &:focus,
//   &:focus-within,
//   &:hover {
//     background-image: url('/assets/img/icons/icons-sprite.svg#calendar-bg');
//     background-color: var(--gray);
//     color: var(--blue);
//   }
// }

// input[type='number']::-webkit-inner-spin-button,
// input[type='number']::-webkit-outer-spin-button {
//   position: absolute;
//   right: 0;
//   top: 0;
//   width: 36px;
//   height: 36px;
// }

// input[type='search']::-webkit-search-cancel-button {
//   position: absolute;
//   opacity: 1;
//   right: 0px;
//   top: 0px;
//   width: 34px;
//   height: 34px;
//   align-items: center;
//   cursor: pointer;
//   margin: 0;
//   appearance: inherit !important;
//   background-color: var(--theme-bg);
//   background-image: url('/assets/img/icons/icons-sprite.svg#cancel-circle-bg');
//   background-repeat: no-repeat;
//   background-position: -114px;
//   background-size: 420px;
//   border-left: 1px solid var(--border);
//   &:hover {
//     background-color: var(--theme-bg-dark);
//     background-position: -151px;
//     background-image: url('/assets/img/icons/icons-sprite.svg#cancel-circle-bg-colored');
//   }
// }

// input[type='text']::-ms-clear,
// input[type='password']::-ms-reveal,
// input[type='date']::-webkit-clear-button {
//   display: none;
// }

// input[type="range" i]::-webkit-slider-thumb,
// input[type="range"]::-webkit-slider-thumb,
// input[type="range"]::-moz-range-thumb {
// 	@include appearance(none);
// 	border-radius: 50%;
// 	background: transparent;
// 	background-color: var(--blue);
// 	width: 20px;
// 	height: 20px;
// }
// input[type="range"]::-webkit-slider-runnable-track {
// 	@include appearance(none);
// 	background: transparent;
// 	background-color: var(--gray-dark);
// }