@import '../../Assets/scss/partials/variables';
@import '../../Assets/scss/partials/mixins';
@import '../../Assets/scss/partials/extensions';

#login-form {
  width: 45%;
  max-width: 100%;
  border: 1px solid var(--grey);
  padding: 2.5em 0;
  margin: 2.5em auto;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  @include phone_and_tabletL {
    border-radius: 0px;
    width: 80%;
    border: 0;
    padding: 0;
  }
  > h1 {
    margin-bottom: 1.1em;
    font-family: PoppinsBold;
    font-size: 2.5em;
    line-height: 1em;
    text-transform: capitalize;
    color: var(--blackashgreen);
    @include phone_and_tabletP {
      font-size: 1.5em;
    }
  }
  > div {
    width: clamp(260px, 22.05em, 22.05em);
    margin: 0 auto;
    @include phone_and_tabletP {
      width: 100%;
    }
  }
  .sep {
    width: 100% !important;
    height: 1px;
    background-color: var(--grey);
    margin-top: 40px !important;
    margin-bottom: 20px !important;
  }
}
