@use "sass:math";

@import "../Assets/scss/partials/variables";
@import "../Assets/scss/partials/mixins";
@import "../Assets/scss/partials/extensions";

.profile-section {
  background: var(--whiteF0);
  min-height: 90vh;
  .profile-header {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 1em;
    justify-content: space-between;
    padding: 1em 2em;
    .button {
      display: flex;
      flex-direction: column;
      gap: 0.5em;
    }
  }
  .user-rating {
    width: 100%;
    padding: 1em 1em;
    margin: 1em 0;
    h2 {
      margin-bottom: 1em;
    }
    div {
      display: flex;
      // justify-content: space-between;
      flex-wrap: wrap;
      p {
        max-width: 36em;
        line-height: 1.4em;
      }
    }
    button {
      margin-top: 20px;
    }
  }
  .profile-galleries {
    list-style-type: none;
    width: 100%;
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    margin-top: 1em;
    .upload {
      background: var(--whiteF0);
      display: grid;
      place-items: center;
      cursor: pointer;
      p {
        transition: var(--trans_3s);
        padding: 5px;
        word-break: break-word;
        &:hover {
          color: var(--blue);
          transition: var(--trans_3s);
        }
      }
      .uploader {
        width: 0px;
        height: 0px;
        visibility: hidden;
        position: absolute;
      }
    }
  }
}
#profile-info-btn-container {
  button {
    @include phone_and_tabletP {
      // width: auto;
      // font-size: 0.7em;
      padding: 10px 10px;
      margin: 0px 10px;
    }
    @include only_phone {
      font-size: 0.5em;
    }
  }
}

#view-profile-header {
  @include only_phone {
    padding: 10px 0 10px 0 !important;
    // display: block;
  }
}
