$colors: (
  'orange': #ff681f,
  'yellow': #ffc107,
  'white': #ffffff,
  'off-white': #f5f5f5,
  'muted': #777777,
  'black': #111111,
  'blackashgreen': #072c38,
  'blue-stone': #006666,
  'blue-washed': #d8f1ff,
  'blue': #33b0f2,
  'blue-semi-dark': #199bdd,
  'blue-dark': #006bc9,
  'charcoal-100': #5d6b70,
  'charcoal-300': #34525c,
  'charcoal': #1d2d33,
  'gray-100': #fbfbfb,
  'gray-200': #f0f0f0,
  'greyashgreen': #5d6b70,
  'grey': #83959B,
  'greyLight': #83959B79,
  'userBG': #f0f0f0,
  'blackOp05': rgba(0, 0, 0, 0.05),
  'blackOp': rgba(242, 242, 242),
  'blackOp0': #00000000,
  'blackOp010': #00000010,
  'blackOp016': #00000029,
  'blackOp029': #0000004a,
  'blackOp076': #000000c2,
  'whitesmoke': #fbfbfb,
  'whiteF0': #f0f0f0,
  'red': #ff3a3a,
  'green': #006666,
  'transparent': transaprent,
  'default': #bdc3c7,
  'info': #5bc0de,
  'primary': #428bca,
  'danger': #d9534f,
  'warning': #f57a28,
  'success': #00d66b
);

$phone-width: 300px;
$phone-landscape-width: 568px;
$tablet-portrait-width: 768px;
$tablet-landscape-width1: 991;
$phone-and-tablet-portrait-width: 1023px;
$tablet-landscape-width: 1024px;
$desktop-width: 1280px;
$laptop-landscape-width: 1366px;
$desktop-wide-width: 1440px;
$ex-desktop-wide-width: 1680px;
$container-padding: 10px;

$btn-size: 3em;
