@import "../../../Assets/scss/partials/variables";
@import "../../../Assets/scss/partials/mixins";
@import "../../../Assets/scss/partials/extensions";

#PricingPlansHome {
  background: var(--white);
  min-height: 90vh;
  padding: 2em 0 5em 0;
  .container {
    > div {
      &:nth-child(1),
      &:nth-child(3) {
        display: none;
      }
    }
  }
}
#PricingPlans {
  background: var(--whiteF0);
  min-height: 90vh;
  .container {
    > div {
      &:nth-child(2) {
        padding: 3em !important;
        border: 1px solid var(--greyLight);
        margin: 3em 0;
        border-radius: 40px;
      }
      .custom_round_box {
        margin: 0;
        min-height: unset;
      }
    }
  }
}
#PricingPlansHome,
#PricingPlans {
  h2 {
    font-family: PoppinsBold;
    font-size: 3.5em;
    line-height: 1em;
    color: var(--blackashgreen);
    margin-bottom: 0.7em;
    margin-left: 0;
    margin-right: 0;
    @include phone_and_tabletL {
      width: 200px;
      font-size: 2.5em;
    }
    @include phone_and_tabletP {
      width: 150px;
      font-size: 2em;
    }
    @include only_phone {
      width: 100px;
      font-size: 1.5em;
    }
  }
  .pricing-list {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
    min-height: 40em;
    margin-top: 1.3em;
    > li {
      border-radius: 15px 15px 100px 100px;
      background: var(--gray-100);
      @extend %transitionAll300;
      cursor: default;
      margin: 0.5em;
      z-index: 0;
      @include phone_and_tabletP {
        margin: 0.5em;
      }
      .front {
        border-radius: 15px;
        height: 37.9em;
        width: 17.7em;
        @extend %transitionAll300;
        background: var(--gray-100);
        display: flex;
        align-items: center;
        flex-direction: column;
        padding: 1.3em;
        z-index: 1;
        @include phone_and_tabletP {
          width: 100%;
        }
        button {
          // width: 9.05em;
          margin: 1.6em 2.45em;
          font-size: 0.9em;
          font-family: PoppinsRegular;
          height: 2.6em;
          border-radius: 22px;
          background-color: var(--blue);
          color: var(--white);
          border: none;
          outline: none;
          cursor: pointer;
          &:hover {
            background-color: var(--blue-dark);
          }
        }
        .feature-list {
          list-style-type: square !important;
          color: var(--blue);
          min-height: 10.85em;
          padding: 1.6em;
          display: flex;
          flex-direction: column;
          gap: 10px;
          max-width: 250px;
          li {
            list-style-type: square !important;
          }
          @include phone_and_tabletL {
            padding: 1em;
          }
          @include only_phoneL {
            padding: 0.2em;
            font-size: 1em;
          }
          p {
            line-height: 1.2;
            @include only_phone {
              line-height: 1;
            }
          }
          a {
            &:hover::before {
              @include only_phone {
                left: -15px;
              }
            }
            &:hover::after {
              @include only_phone {
                min-width: 5em !important;
                right: 35px;
                left: unset;
              }
            }
          }
        }
      }
      &:hover {
        background: var(--blue);
        @extend %transitionAll300;
        @include phone_and_tabletP {
          background: var(--transparent);
        }
        .front {
          @extend %transitionAll300;
          background: var(--gray-100);
          margin-top: 20px;
          @include phone_and_tabletP {
            margin-top: 0px;
          }
        }
      }
    }
  }
}

.current-plan {
  @include only_phone {
    gap: 0.2em !important;
  }
  @include tabletP {
    gap: 0.5em !important;
  }
  a {
    &:hover::before {
      @include only_phone {
        left: -15px;
      }
    }
    &:hover::after {
      @include only_phone {
        min-width: 4em !important;
        right: 35px;
        left: unset;
      }
    }
  }
}
