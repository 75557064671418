.modal {
  width: 100%;
  height: 100vh;
  display: grid;
  place-items: center;
  background: var(--transparent);
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 9999;
  .blur {
    position: absolute;
    width: 100%;
    height: 100%;
    background: var(--blackOp05);
    backdrop-filter: blur(3px);
    z-index: 0;
  }
  .modal-container {
    width: 43.1em;
    z-index: 1;
    background: var(--white);
    border-radius: 1em;
    display: flex;
    align-items: space-between;
    justify-content: flex-start;
    flex-direction: column;
    @include phone_and_tabletP {
      width: 90%;
    }

    .modal-header {
      padding: 1em 2em;
    }
  }
  .modal-content {
    padding: 1em 2em;
    .spacing {
      margin: 1em 0 1em 0;
    }
  }
  .modal-footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 1em;
    padding: 1em 2em;
    & button {
      height: 3.2em !important;
      width: 9.5em !important;
      min-width: 8.5em !important;
      padding: 0 2em !important;
      @include phone_and_tabletL{
        height: 2.0em !important;
        width: 8.0em !important;
      }
    }
  }
}

.topSkillsContent {
  display: flex;
  padding: 2em 1em;
  flex-direction: column;
  position: relative;
  @include phone_and_tabletP {
    width: inherit;
  }
  
}
